/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
// Swiper
@import 'swiper/scss';
@import 'swiper/scss/autoplay';
@import 'swiper/scss/keyboard';
@import 'swiper/scss/pagination';
@import 'swiper/scss/scrollbar';
@import 'swiper/scss/zoom';
@import 'swiper/scss/parallax';
@import 'swiper/scss/effect-fade';
@import '@ionic/angular/css/ionic-swiper';
@import "./theme/material-table.css";
@import "./theme/grid-table.css";
hr {
    border-top-color: var(--ion-color-primary);
    border-right-color: var(--ion-color-primary);
    border-bottom-color: var(--ion-color-primary);
    border-left-color: var(--ion-color-primary);
    background-color: var(--ion-color-primary);
}

h1.page-title {
    font-size: 32px;
}

p {
    font-weight: 500;
}

.ion-full-width {
    width: 100%;
}

.ion-text-italic {
    font-style: italic;
}

.ion-text-bold {
    font-weight: bold;
}

ion-modal.fullscreen {
    --width: 100%;
    --height: 100%;
    --border-radius: 0;
}

ion-modal.media-player app-media-player {
    overflow-y: scroll;
}

ion-chip.sermon-series {
    margin: 3px 0 10px;
}

ion-button.text-danger {
    --background: var(--ion-color-white);
    --color: var(--ion-color-danger);
    --background-activated: var(--ion-color-danger);
}

ion-button.text-danger.ion-activated {
    --color: var(--ion-color-white);
}

ion-button.text-danger.button-outline {
    --border-color: var(--ion-color-danger);
}

ion-card-content+ion-card-header {
    padding-top: 0;
}

ion-content .header-collapse-condense ion-toolbar {
    --background: none;
}

// ion-card>ion-img {
//     max-width: 100%;
// }
ion-card-content.card-body {
    padding-top: 0;
    p {
        font-size: 16px;
        line-height: 1.6rem;
    }
}

ion-card-content.multiple-paragraphs {
    p {
        color: var(--color);
        font-size: 16px;
        margin-bottom: 1rem;
    }
}

ion-list.item-separate {
    background: none;
    ion-list-header {
        background: none;
    }
    ion-item {
        margin-bottom: 1rem;
        --border-radius: 8px;
    }
}

ion-card {
    margin-left: 0;
    margin-right: 0;
}

small {
    margin: 2rem auto;
    display: block;
}

ion-segment-button {
    min-width: fit-content;
    overflow: initial;
}

ion-segment-button {
    --indicator-color: var(--ion-color-primary);
    border-radius: 0;
}

ion-datetime {
    border-radius: 16px;
    margin: 1rem auto;
}

.table-responsive {
    overflow: scroll;
    ion-row {
        flex-wrap: nowrap;
    }
}

// .swiper-pagination-bullets {
//     bottom: 0rem !important;
// }

/* Material Design styles */

ion-segment-button.md {
    --color: var(--ion-color-black);
    --color-checked: var(--ion-color-primary);
    --indicator-height: 4px;
}


/* iOS styles */

ion-segment-button.ios {
    --color-checked: #fff;
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
}

.embed-responsive-16by9 {
    padding-bottom: 56.25%;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0px;
}

@media (prefers-color-scheme: dark) {
    ion-item {
        --background: var(--ion-card-background) !important;
    }
    table,
    ion-list-header:not(.ion-color-clear) {
        background: var(--ion-card-background) !important;
    }
}

.google-pay-button {
    max-width: 13rem;
    margin: 0 auto;
}

.label-justify {
    display: flex;
    justify-content: space-between;
    -webkit-box-pack: justify;
}

.form-item {
    color: var(--ion-card-form-item);
    margin-bottom: 1.5rem;
    ion-item {
        --padding-start: 0;
        --inner-padding-end: 0;
        --border-color: transparent;
    }
    ion-input,
    ion-textarea,
    ion-select {
        border: .1rem solid;
        border-radius: 6px;
        margin: 8px 0;
        --padding: 3px 8px;
        --padding-start: 12px;
        --padding-end: 12px;
    }
    ion-input {
        label.input-wrapper {
            padding-inline-start: 10px;
        }
    }
}

.form-item.ion-no-margin {
    margin-bottom: 3px;
}

.ion-align-items-center-page {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    strong {
        font-size: 20px;
        line-height: 26px;
    }
    p {
        font-size: 16px;
        line-height: 22px;
        color: var(--ion-color-dark-tint);
    }
    a {
        text-decoration: none;
    }
}

.ion-border {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 8px;
    border: .07rem solid;
    padding: 1.5rem .5rem;
}

.account-page {
    margin-top: 5rem;
}

.ion-text-underline {
    text-decoration: underline;
}

.ion-overflow-x {
    overflow-x: scroll;
}

.ion-overflow-y {
    overflow-y: scroll;
}